<template>
  <div class="count-page content-block">
    <fm-title :title-text="this.year + ' ' + (this.config ? this.config.title : '')" @clickTitleMenu="clickTitleMenu" :title-menus="[{key: 'base', label: '基础信息'}, {key: 'back', label: '返回'}]">
    </fm-title>
    <div class="count-content">
      <div style="width:100%;height:calc(100% - 40px);">
      <fm-table-new
        :columns="columns"
        :auto-height="true"
        v-loadingx="loading"
        :border="true"
        ref="table"
        @cell-click="cellClick"
        :data-list="dataList"
        :stripe="false"
        size="small"
        emptyPlaceholder="-">
      </fm-table-new>
      </div>
    </div>
    <fm-modal v-model="modal" :title="detailTitle" v-if="modal" width="1100px">
      <div style="height:60vh;">
        <fm-table-new
          :columns="columnsDetail"
          :auto-height="true"
          border="row"
          ref="table"
          :data-list="detailList"
          :stripe="false"
          size="small"
          emptyPlaceholder="-">
        </fm-table-new>
      </div>
    </fm-modal>

  </div>
</template>

<script>
import {
  dateOperating
} from '@/fmlib'

import {
  countWorkerRequest,
  countGroupRequest
} from '../../api'

import CountCell from './cmp/countCell'

import {
  getCountData,
  countWorkerColumns
} from './lib'

const getEntryYear = (entryDate, now = new Date()) => {
  let age = null
  if (entryDate) {
    let d1 = dateOperating.computeDay({days: 0, date: entryDate})
    age = now.getFullYear() - d1.getFullYear()
    if (now.getMonth() < d1.getMonth() || (now.getMonth() === d1.getMonth() && now.getDate() < d1.getDate())) {
      age = age - 1
    } else if (now.getMonth() > d1.getMonth() || (now.getMonth() === d1.getMonth() && now.getDate() > d1.getDate())) {
      age = age + 0.1
    }
  }
  return age
}

export default {
  data () {
    return {
      modal: false,
      year: null,
      title: null,
      config: {},
      loading: false,
      countGroup: null,
      countWorkerList: [],
      detailList: [],
      detailTitle: '',
      dataList: [],
      columnsDetail: countWorkerColumns
    }
  },
  async activated () {
    this.year = this.$router.history.current.params.year
    this.config = this.$router.history.current.params.config
    this.countGroup = null
    this.countWorkerList = []
    if (!this.year || !this.config) {
      this.$router.go(-1)
    } else {
      await this.loadGroup()
      await this.loadDetail()
      this.dataList = getCountData(this.countWorkerList, this.config)
    }
  },
  computed: {
    columns () {
      if (!this.config || !this.config.rowGroupList) {
        return []
      }
      let data = [{
        title: '',
        width: 100,
        fixed: 'left',
        field: '__lineLabel',
        render: (h, rowData) => {
          return h(CountCell, {
            props: {
              content: rowData['__lineLabel']
            }
          })
        }
      },
      {
        title: '合计',
        width: 100,
        fixed: 'left',
        field: '__count',
        render: (h, rowData) => {
          return h(CountCell, {
            props: {
              content: rowData['__count'],
            }
          })
        }
      }]
      this.config.rowGroupList.forEach((rowGroup) => {
        if (rowGroup && rowGroup.fields && rowGroup.fields.length > 0)  {
          data = data.concat(rowGroup.fields.map(field => {
            let key = field.relatedKeys.join('_')
            return {
              title: field.label,
              width: 100,
              field: key,
              render: (h, rowData) => {
                return h(CountCell, {
                  props: {
                    content: rowData[key]
                  }
                })
              }
            }
          }))
        }
      })
      return data
    }
  },
  methods: {
    clickTitleMenu (key) {
      if (key === 'base') {
        this.detailList = this.countWorkerList.filter(v => v)
        this.detailTitle = '全部基础信息 数量：' + this.detailList.length
        this.columnsDetail = countWorkerColumns
        this.modal = true
      } else if (key === 'back') {
        this.$router.go(-1)
      }
    },
    cellClick (parm) {
      let conditionList = parm.row[parm.column.field + '_condition'] || []
      let relatedKeys = parm.row[parm.column.field + '_relatedKeys'] || []
      let data = this.countWorkerList.filter(v => {
        let pass = true
        let i = 0
        while (i < conditionList.length) {
          let conditionItem = conditionList[i]
          if (conditionItem) {
            pass = pass && conditionItem(v)
          }
          i += 1
        }
        return pass
      })
      this.detailList = data
      this.detailTitle = parm.row.__lineLabel + ' & ' + parm.column.title + ' 数量：' + this.detailList.length
      this.columnsDetail = countWorkerColumns.filter(v => ['name', 'phone'].includes(v.field) || relatedKeys.length === 0 || relatedKeys.includes(v.field)).map(v => Object.assign({}, v))
      if (this.columnsDetail.length != countWorkerColumns.length) {
        this.columnsDetail.forEach(v => v.fixed = null)
      }
      this.modal = true
    },
    async loadGroup () {
      let datas = await countGroupRequest.get({
        name: this.year
      })
      this.countGroup = datas.find(v => v.name === this.year)
    },
    async loadDetail () {
      if (this.countGroup) {
        let data = await countWorkerRequest.get({
          countGroupId: this.countGroup.id
        })
        data = data.filter(v => !v.isDiscipline)
        data.forEach(v => {
          v.entryYear = getEntryYear(v.correctionDate, new Date(this.year + '-12-31 00:00:00'))
        })
        this.countWorkerList = data
      }
    }
  }
}
</script>

<style lang="less">
.count-page {
  margin: 20px;
  height: calc(100% - 40px);
  .count-content {
    .fm-table-cell-align-left {
      text-align: center;
      justify-content: center;
    }
  }
}
</style>

<style scoped lang="less">
.count-page {
  margin: 20px;
  height: calc(100% - 40px);
}
.count-content {
  width: 100%;
  height: calc(100% - 48px);
}
.title-s {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>