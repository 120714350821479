<template>
  <a class="count-cell" href="javascript:;">
    <div>{{content}}</div>
  </a>
</template>

<script>
export default {
  props: {
    content: {
      type: [String, Number]
    }
  }
}
</script>

<style lang="less" scoped>
.count-cell {
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  width: 100%;
}
.count-cell:hover {
  color: #F4628F;
  width: 100%;
}
</style>